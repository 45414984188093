#main {
    box-sizing: border-box;
/*    scroll-snap-type: y proximity;*/
/*    overflow-y: scroll;*/
    overflow-x: hidden;
/*    height: 100vh;*/
/*    scroll-behavior: smooth;*/
}

/*section {*/
/*    scroll-snap-align: start;*/
/*    scroll-behavior: smooth;*/
/*    transition: transform 0.5s, opacity 0.5s;*/
/*}*/

/*#contact {*/
/*    scroll-padding: 100px;*/
/*}*/